import { API_SERVER_HOST } from "./apiConfig";
import jwtAxios from "../util/jwtUtil";

const host = `${API_SERVER_HOST}/api/my`;

// 회원정보 수정은 로그인이 될 수 있는 사용자만 가능하므로 jwtAxios 를 이용한다.
export const modifyMember = async (member) => {
  const header = { headers: { "Content-Type": "multipart/form-data" } };
  console.log("modifyMember-header : ", header);
  const res = await jwtAxios.put(`${host}/modify`, member, header);
  return res.data;

  // try {
  //   const res = await jwtAxios.put(`${host}/modify`, member, header);
  //   return res.data;
  // } catch (error) {
  //   // Axios에서 발생한 오류를 처리합니다.
  //   alert("modifyMember-err : " + error);
  //   throw error;
  // }
};

/**
 * 내 구매내역 (POST)
 */
export const getMyInfo = async (param) => {
  console.log("^^new-getMyInfo");
  // await : 비동기 작업이 완료되기를 기다리고 그 결과를 받아옴.
  // 파라미터: RequestParam 으로 받는다.
  const res = await jwtAxios.post(`${host}?memberId=${param.memberId}`);
  return res;
};

/**
 * 내 구매내역 (GET), API 사용못함(GET 방식 없음)
 */
export const getMyOrder = async (params) => {
  const { memberId } = params;

  const res = await jwtAxios.get(`${host}/order`, {
    params: { memberId: memberId },
  });

  return res.data;
};

/**
 * 게임 기록 가져오기
 */
export const getRecord = async (params) => {
  const { memberId, limit } = params;
  const res = await jwtAxios.get(`${host}/record`, {
    params: { memberId: memberId, limit: limit },
  });
  return res.data;
};
