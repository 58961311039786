import React, { useCallback, useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  setGameControl,
  resetGameControl,
} from "../../redux/slices/gameControlSlice";
import { Link, useNavigate, useParams } from "react-router-dom";
import { numberWithCommas } from "../../util/numberUtil";
import { getTimeDifferenceTimeStamp } from "../../util/dateUtil";

/**
 * 게임 종료 또는 무조건 다음 게임 진행한다. (다음 선택지 진행이 아님)
 * 모달창 오픈 조건
 *   > 정답
 *   > 오답
 *        1. 마지막 선택지
 *        2. 하트 모두 소진
 *
 */
const GameModal = () => {
  const [img, setImg] = useState("");
  const [bonusImg, setBonusImg] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [gameDurationText, setGameDurationText] = useState("");

  const { pridx } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isAutoRun = useSelector(
    (state) => state.gameSetSlice.isAutoRun // state.gameSetSlice ==> store 의 gameSetSlice 를 가르킨다.
  );

  const gcAtStart = useSelector((state) => state.gameSlice.gcAtStart);
  const gcAtEnd = useSelector((state) => state.gameSlice.gcAtEnd);
  const rank = useSelector((state) => state.gameSlice.rank);

  const prName = useSelector((state) => state.gameControlSlice.prName);

  const hodu = useSelector((state) => state.gameControlSlice.hodu);

  const gameCount = useSelector(
    (state) => state.gameControlSlice.gameCount,
    shallowEqual
  );

  const totalGameCount = useSelector(
    (state) => state.gameControlSlice.totalGameCount
  );

  const optionCount = useSelector(
    (state) => state.gameControlSlice.optionCount
  );

  const currentGameInfo = useSelector(
    (state) => state.gameControlSlice.currentGameInfo,
    shallowEqual
  );

  const isModal = useSelector((state) => state.gameControlSlice.isModal);

  const isCorrect = useSelector((state) => state.gameControlSlice.isCorrect);

  const heart = useSelector((state) => state.gameControlSlice.heart);

  const isGameOpen = useSelector((state) => state.gameControlSlice.isGameOpen);

  const gcPoint = useSelector((state) => state.gameControlSlice.gcPoint);

  const totalPoint = useSelector((state) => state.gameControlSlice.totalPoint);

  const timer_auto = useRef(null);
  console.log("GameModal-timer_auto.current : ", timer_auto);

  const rePlayFn = (e) => {
    if (e) e.preventDefault();
    navigate({ pathname: `/game/index/${pridx}` }, { replace: true });
    dispatch(resetGameControl());
  };

  const homeFn = (e) => {
    if (e) e.preventDefault();
    navigate({ pathname: `/main/game-list` }, { replace: true });
    dispatch(resetGameControl());
  };

  const nextPlayFn = (e) => {
    if (e) e.preventDefault();

    if (gameCount >= totalGameCount - 1) {
      alert("마지막 게임입니다.");
      return false;
    }

    // 자동 실행시 다음 버튼 클릭시 자동모드에 의해 실행된 타이머를 정리한다.
    if (isAutoRun && e) {
      console.log("^^GameModal-타이머정리2 : ", timer_auto);
      clearTimeout(timer_auto.current);
      timer_auto.current = null; // 타이머 초기화
    }

    // 마지막 선택지라면
    dispatch(
      setGameControl({
        isModal: false,
        isOpenDoor: true,
        optionCount: 0, // 선택지 0 부터
        gameCount: gameCount + 1, // 다음 게임
        isCorrect: null,
        gcIdx: 0,
        optIdx: 0,
        gcPoint: 100,
        //gcAtStart: 0,  주석:어차피 게임 초기화 때 할당됨
        gcAtEnd: 0,
        gameType: "",
      })
    );
  };

  const rankFn = (e) => {
    if (e) e.preventDefault();
    navigate({ pathname: `/rank/all` }, { replace: true });
    dispatch(resetGameControl());
  };

  useEffect(() => {
    console.log("^^currentGameInfo:", currentGameInfo);
    if (currentGameInfo === null) {
      setImg(``);
      setBonusImg(``);
    } else {
      const newImgPath = `/game_contents/${currentGameInfo.code.substring(
        0,
        4
      )}/portrait/${currentGameInfo.code}.webp`;
      setImg(newImgPath);

      const newBonusImgPath = `/game_contents/${currentGameInfo.code.substring(
        0,
        4
      )}/bns/${currentGameInfo.code}.webp`;
      setBonusImg(newBonusImgPath);

      // const img = new Image();
      // img.src = newImgPath;

      // img.onload = () => {
      //   // 이미지가 성공적으로 로드된 경우
      //   console.log("^^어진 Load");
      // };

      // img.onerror = () => {
      //   // 이미지 로드 실패 시 처리
      //   console.log("^^어진 Fail");
      //   setImg("/img/portrait/default.png"); // 기본 이미지 경로 설정
      // };
    }
  }, [currentGameInfo]);

  useEffect(() => {
    if (!isGameOpen && isCorrect) {
      setModalTitle("게임 종료🏆✨");
    } else if (!isGameOpen && !isCorrect) {
      setModalTitle("게임 종료😥");
    } else if (isCorrect) {
      setModalTitle("맞췄어요🥳");
    } else if (!isCorrect) {
      setModalTitle("아쉬워요😥");
    } else {
      setModalTitle("!!");
    }

    if (!isGameOpen) {
      console.log("^^gcAtStart :", gcAtStart, "#", gcAtEnd.length);
      if (gcAtStart) {
        const startTimeStamp = gcAtStart[0];
        const endTimeStamp = gcAtEnd[gcAtEnd.length - 1];
        console.log("^^startTimeStamp ", startTimeStamp, "#", endTimeStamp);

        const diffTime = getTimeDifferenceTimeStamp(
          startTimeStamp,
          endTimeStamp,
          "분",
          "초"
        );
        setGameDurationText(diffTime);

        console.log(diffTime);
      }
    }

    // 타이머 초기화
    if (timer_auto.current) {
      clearTimeout(timer_auto.current);
      timer_auto.current = null;
    }

    // 자동 + 전체 카운트 이내
    if (isModal && isGameOpen && isAutoRun && gameCount <= totalGameCount) {
      autoRun();
    }
  }, [isModal]);

  const autoRun = () => {
    console.log("^^GameModal-autoRun:", timer_auto);
    // 타이머가 이미 설정된 경우 새로 설정하지 않도록 방지
    console.log("^^timer_auto start");
    timer_auto.current = setTimeout(() => {
      nextPlayFn();
    }, 5000);

    // 타이머를 해제하는 함수
    return () => {
      console.log("^^GameModal-타이머정리1 : ", timer_auto);
      clearTimeout(timer_auto.current);
      timer_auto.current = null; // 타이머 초기화
    };
  };

  if (!isModal) return null;

  return (
    <div className="modal-popup game-popup">
      <div
        className={`popup ${
          isGameOpen
            ? isCorrect
              ? "success-popup"
              : "fail-popup"
            : "gameover-popup"
        }`}
      >
        {isCorrect && <div className="pop-bg"></div>}
        <div className="inner">
          <div className="layer">
            {isGameOpen ? (
              <>
                <div className="tit">
                  <h2>
                    <em></em>
                    {modalTitle}
                  </h2>
                </div>
                <div className="point-box">
                  <em></em>
                  <p>{numberWithCommas(totalPoint)}</p>

                  <p style={{ paddingLeft: "12px" }}> 📈{rank}위</p>
                </div>
                <div className="king-box">
                  <div className="summary">
                    {currentGameInfo.gameType === "KING" ? (
                      <>
                        <div className="thum">
                          <img src={img} alt="" />
                        </div>
                        <div className="info">
                          <div className="generation">
                            <p>{currentGameInfo.subject}</p>
                            <h3>{currentGameInfo.name1}</h3>
                          </div>
                          <div className="line name">
                            <b>휘</b>
                            <p>{currentGameInfo.name2} </p>
                          </div>
                          <div className="line birth-death">
                            <b>출생/사망</b>
                            <p>
                              {currentGameInfo.birth} ~ {currentGameInfo.death}{" "}
                            </p>
                          </div>
                          <div className="line reign">
                            <b>재위</b>
                            <p>
                              {currentGameInfo.reignStart} ~{" "}
                              {currentGameInfo.reignEnd}
                            </p>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="thum">
                          <img src={bonusImg} alt="" />
                        </div>
                      </>
                    )}
                  </div>
                  <div className="detail">
                    <span>{currentGameInfo.intro}</span>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="tit">
                  <h2>
                    <em></em>
                    {modalTitle}
                  </h2>
                </div>

                <div className="game-name">
                  <h2>{prName}</h2>
                </div>
                <div className="game-result">
                  <div className="point-box">
                    <em></em>
                    <p>{numberWithCommas(totalPoint)}</p>
                  </div>
                  <div className="detail">
                    <div>
                      <div className="walnut">
                        <em> </em>
                        <p>{hodu}개</p>
                      </div>
                      <div className="rank">
                        <em></em>
                        <p>{rank}위</p>
                      </div>
                    </div>
                    <div>
                      <div className="game-num">
                        <b>게임수 : </b>
                        <span> {gameCount + 1}개</span>
                      </div>
                      <div className="game-time">
                        <b>게임시간 : </b> <span>{gameDurationText}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            <div className="btn">
              <Link to="#" className="return" onClick={(e) => rePlayFn(e)}>
                돌아가기
              </Link>

              <Link to="#" className="home" onClick={(e) => homeFn(e)}>
                홈
              </Link>

              {isGameOpen ? (
                heart > 0 && (
                  <Link
                    to="#"
                    className="continue"
                    onClick={(e) => nextPlayFn(e)}
                  >
                    계속
                  </Link>
                )
              ) : (
                <Link to="#" className="go-ranking" onClick={(e) => rankFn(e)}>
                  랭킹
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameModal;
