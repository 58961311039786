import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import useCustomLogin from "../../hooks/useCustomLogin";
import {
  getAccessToken,
  getMemberWithAccessToken,
  getServerAccessToken,
  getServerMemberWithAccessToken,
} from "../../api/naverApi"; // naver API 사용
import { useDispatch } from "react-redux";
import { login } from "../../redux/slices/loginSlice";

const GoogleRedirectPage = () => {
  const [searchParams] = useSearchParams();

  const { moveToPath } = useCustomLogin();

  const dispatch = useDispatch();

  // 네이버에서 받은 인가 코드
  const authCode = searchParams.get("code");

  console.log("^^authCode : ", authCode);

  useEffect(() => {
    // 네이버에서 받은 인가 코드를 이용해 다시 네이버의 엑세스 토큰을 받아서 다시 회원정보를 가져옴. (서버에서 모두 처리함)
    getServerMemberWithAccessToken(authCode).then((memberInfo) => {
      console.log("^^ memberInfo : ", memberInfo);

      dispatch(login(memberInfo));

      //소셜 회원이 아니라면
      if (memberInfo && !memberInfo.social) {
        moveToPath("/");
      } else {
        moveToPath("/my/profile");
      }
    });
  }, [authCode]);

  console.log("^^naver-authCode : " + authCode);

  return (
    <div className="modal-popup loading">
      <div className="sword-img"></div>
      <ul className="loading-ber">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
  );
};

export default GoogleRedirectPage;
