import axios from "axios";
import { API_SERVER_HOST } from "./apiConfig";
import jwtAxios from "../util/jwtUtil";

const host = `${API_SERVER_HOST}/api/member`;

export const loginPost = async (loginParam, thunkAPI) => {
  try {
    console.log("^^ loginPost-param: ", loginParam);

    const header = { headers: { "Content-Type": "x-www-form-urlencoded" } };

    const form = new FormData();
    form.append("username", loginParam.memberId);
    form.append("password", loginParam.passwd);

    const res = await axios.post(`${host}/login`, form, header);
    return res.data;
  } catch (error) {
    // ^^error 일단 보류
    // 예외 발생시 loginSlice > extraReducers > loginPostAsync.rejected 로 가서 상태값인 error 에 오류메시지가 들어간다.
    // 상태값인 error 은 다른 컴포넌트들에서 {loginState.error } 으로 에러메시지를 보여주면 된다.
    // thunkAPI.rejectWithValue를 사용하여 에러 메시지를 반환
    // thunkAPI 의 여러 유틸리티들 ==> dispatch, getState, rejectWithValue, extra, requestId, signal
    console.log("^^ error : " + error + " #message : " + error.message);
    return thunkAPI.rejectWithValue(error.message);
  }
};
