//기본 상태
export const getBasicState = (code) => {
  const map = {
    N: "기본",
    C: "취소",
    D: "삭제",
  };
  return map[code] || "잘못된코드";
};

// 주문상태 코드
export const getOrderState = (code) => {
  const map = {
    W: "주문대기",
    C: "취소",
    S: "주문완료",
  };

  return map[code] || "잘못된코드";
};

// 결제상태 코드
export const getPaymentState = (code) => {
  const map = {
    W: "입금대기",
    C: "입금완료",
    U: "미입금",
  };

  return map[code] || "잘못된코드";
};

// 결제방법 코드
export const getPayType = (code) => {
  const map = {
    C: "카드결제",
    V: "가상계좌",
  };

  return map[code] || "잘못된코드";
};
