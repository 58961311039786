import React, { startTransition, useEffect, useRef, useState } from "react";
import BasicLayout from "../../layout/BasicLayout";
import TypeIt from "typeit-react";
import { applyStyles } from "../../util/stringUtil";

const TypeItJsPage = () => {
  const [bgColor, setBgColor] = useState("white");

  const [sec, setSec] = useState(0);

  const txt =
    '조선의 <b>조라는 묘호 남용의 단초를 제공한 왕으로</b>, 아버지인 "세조"가 대단한 업적을 세웠다 볼 수 없었음에도 묘호(세조)를 강력히 주장했다.';
  const customStyle = { color: "blue", fontWeight: "bold" };
  const styledText = applyStyles(txt, customStyle);

  const containerRef = useRef(null); // ref 생성

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSec((sec) => sec + 1);
    }, 1000);

    // 클린업 함수
    return () => clearInterval(intervalId);
  }, []);

  const fncInit = () => {
    console.log(`======1. 시작전 작업`);
  };

  const fncComplete = () => {
    console.log(`======2. 마무리 작업`);
  };

  const fnc2 = () => {
    const t1 = setTimeout(() => {
      console.log(`======2. 시작전 작업`);
    }, 3000);
    return () => clearTimeout(t1);
  };

  return (
    <BasicLayout pageClassName="setting bg-map">
      <div>
        <div style={styles.default}>타이핑 테스트</div>

        <div style={styles.default}>
          <div id="container">
            <h1>TypeIt Example - {sec}</h1>
            <TypeIt
              getBeforeInit={(instance) => {
                // beforeInit 대신 getBeforeInit을 사용
                console.log(
                  "======타이핑 애니메이션 시작 전 작업 처리 : " + sec
                );

                fncInit();

                document.getElementById("container").style.backgroundColor =
                  "blue";

                // 타이핑 애니메이션 시작 전, instance를 조작할 수 있습니다.
                instance
                  .pause(3000)
                  .type("이 문장은 애니메이션으로 나타납니다 : ")
                  .pause(3000);

                // instance 반환 (필수)
                return instance;
              }}
              options={{
                speed: 50,
                afterComplete: (instance) => {
                  console.log("======After Complete : " + sec);
                },
                beforeString: (instance, str, strIndex) => {
                  console.log(`======Before typing string: ${str} : ${sec}`);
                },
                afterString: (instance, str, strIndex) => {
                  console.log(`======After typing string: ${str} : ${sec}`);
                  //fncComplete();
                },
                beforeDelete: (instance, str, strIndex) => {
                  console.log(`======Before deleting string: ${str} : ${sec}`);
                },
                afterDelete: (instance, str, strIndex) => {
                  console.log(`======After deleting string: ${str} : ${sec}`);
                },
              }}
            />
          </div>
        </div>
      </div>
    </BasicLayout>
  );
};

const styles = {
  default: {
    margin: "20px",
    padding: "20px",
    border: "1px solid #000",
  },
  sub: {
    margin: "20px",
    padding: "20px",
  },
  modal: {},
};

export default TypeItJsPage;
