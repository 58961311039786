import { useDispatch, useSelector } from "react-redux";
import { getMyInfoAsync, resetMemberState } from "../redux/slices/memberSlice";

const useCustomMember = () => {
  // state 는 전체 상태, state.cartSlice 는 memberSlice 리듀서가 관리하는 상태 (initState)
  const myInfo = useSelector((state) => state.memberSlice);

  const dispatch = useDispatch();

  const refreshMyInfo = (param) => {
    dispatch(getMyInfoAsync(param));
  };

  // memberState를 초기화하는 함수 추가
  const resetMyInfo = () => {
    dispatch(resetMemberState());
  };

  return { myInfo, refreshMyInfo, resetMyInfo };
};

export default useCustomMember;
