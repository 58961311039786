import React, { useEffect, useState } from "react";
import OrderHistoryRowComponent from "./OrderHistoryRowComponent";
import { getMyOrder } from "../../api/myApi";
import useCustomLogin from "../../hooks/useCustomLogin";
import withLoginCheck from "../../hoc/withLoginCheck";
import { useDispatch } from "react-redux";
import { resetMemberState } from "../../redux/slices/memberSlice";
import useCustomMember from "../../hooks/useCustomMember";

const OrderHistoryComponent = () => {
  const [serverData, setServerData] = useState([]);

  const { isLogin, loginState, moveToLogin } = useCustomLogin();

  const { resetMyInfo } = useCustomMember();

  if (!isLogin) {
    resetMyInfo(); // 상태 초기화
    moveToLogin();
  }

  const memberId = loginState.memberId;

  useEffect(() => {
    getMyOrder({ memberId: memberId })
      .then((response) => {
        // API 응답 데이터가 배열인지 확인
        if (Array.isArray(response)) {
          setServerData(response); // 상태에 데이터 할당
        } else {
          console.error("Expected an array but received:", response);
        }
      })
      .catch((err) => {
        if (err.error === "REQUIRE_LOGIN") {
          moveToLogin();
        }
      });
  }, [memberId]);

  return (
    <div className="tab-cont">
      <div className="table">
        <ul className="th">
          <li className="no">
            <em></em>
            <p>구매번호</p>
          </li>
          <li className="date">
            <em></em>
            <p>구매일시</p>
          </li>
          <li className="product">
            <em></em>
            <p>구매내역</p>
          </li>
          <li className="state">
            <em></em>
            <p>구매상태</p>
          </li>
          <li className="payment">
            <em></em>
            <p>결제내역</p>
          </li>
        </ul>
        <div className="list">
          {serverData.length > 0 ? (
            serverData.map((order) => {
              return (
                <OrderHistoryRowComponent key={order.orderIdx} data={order} />
              );
            })
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default withLoginCheck(OrderHistoryComponent);
