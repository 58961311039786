import React, { useEffect, useState } from "react";
const TimmerComponent = ({ data }) => {
  const [key, setKey] = useState(0); // 애니메이션 초기화를 위해 key 사용

  console.log("^^data:", data);

  useEffect(() => {
    if (data) {
      setKey((prevKey) => prevKey + 1); // 데이터가 true일 때 key 값을 변경하여 컴포넌트를 재랜더링
    }
  }, [data]);

  return (
    <div className="timer">
      <div
        className={`clock ${data ? "animate" : ""}`}
        key={`clock-${key}`}
      ></div>
      <div
        className={`second ${data ? "animate" : ""}`}
        key={`second-${key}`}
      ></div>
    </div>
  );
};

export default TimmerComponent;
