import React from "react";
import LoginComponent from "../../components/member/LoginComponent";
import BasicLayout from "../../layout/BasicLayout";

const LoginPage = () => {
  return (
    <BasicLayout pageClassName="login bg-map">
      <div className="login-content">
        <h2 className="tit">
          <b>왕</b>을 만나러 <br />
          가보실까요?
        </h2>

        <LoginComponent />
      </div>
      <p className="inquiry-txt">
        로그인 오류시 문의 {process.env.REACT_APP_EMAIL}
      </p>
    </BasicLayout>
  );
};

export default LoginPage;
