import React from "react";
import { useSelector } from "react-redux";

const DoorComponent = () => {
  console.log("-----DoorComponent");

  const isOpenDoor = useSelector((state) => state.gameControlSlice.isOpenDoor);

  return (
    <div
      className="intro"
      role="button"
      tabIndex="0"
      style={isOpenDoor ? openStyle : closeStyle}
    >
      <div className={`door l-door ${isOpenDoor ? "open" : ""}`}></div>
      <div className={`door r-door ${isOpenDoor ? "open" : ""}`}></div>
    </div>
  );
};

const closeStyle = { zIndex: 0, display: "none" };
const openStyle = { zIndex: 10, display: "block" };

export default DoorComponent;
