import { Link, Outlet, useLocation } from "react-router-dom";
import BasicLayout from "../../layout/BasicLayout";
import useCustomLogin from "../../hooks/useCustomLogin";
import RecordComponent from "../../components/my/RecordComponent";

/**
 * /my 내의 경로에 대한 레이아웃 역할
 */
const IndexPage = () => {
  const { isLogin, moveToLoginReturn } = useCustomLogin();

  if (!isLogin) {
    return moveToLoginReturn();
  }

  return (
    <BasicLayout pageClassName="record">
      <div className="content record-content">
        <div className="list-st rc-list">
          <ul className="tabs mypage-tabs">
            <li>
              <Link to="../profile">프로필</Link>
            </li>
            <li className="on">
              <Link to="../record">게임 기록</Link>
            </li>
            <li>
              <Link to="../history">구매 내역</Link>
            </li>
          </ul>
          <RecordComponent />
        </div>
      </div>
    </BasicLayout>
  );
};

export default IndexPage;
