import axios from "axios";
import { API_SERVER_HOST } from "./apiConfig";
import jwtAxios from "../util/jwtUtil";

const host = `${API_SERVER_HOST}/api/order`;

export const postAdd = async (order) => {
  // axios 는 기본적으로 Content-Type=application/json 을 이용하기 때문에 파일 업로드를 같이 할 경우 아래처럼 헤더를 설정한다.
  const header = { headers: { "Content-Type": "application/json" } };

  // 경로 뒤 '/' 주의
  //const res = await axios.post(`${host}/`, order, header)
  const res = await jwtAxios.post(`${host}`, order, header); // 토큰인증을 위해 jwtAxios 사용

  return res;
};

export const getOneOrder = async (params) => {
  const { memberIdx, memberId } = params;

  const res = await jwtAxios.get(`${host}/my`, {
    params: { memberIdx: memberIdx, memberId: memberId },
  });

  return res.data;
};
