import useCustomLogin from "../../hooks/useCustomLogin";

const LogoutComponent = () => {
  const { doLogout, moveToPath } = useCustomLogin();

  // 로그아웃은 파라미터가 필요없다.
  const handleClickLogout = () => {
    doLogout();

    alert("로그아웃되었습니다.");
    moveToPath("/");
  };

  return (
    <div className="sns-login">
      <div>
        <button
          className="rounded p-4 w-36 bg-red-500 text-xl  text-white"
          onClick={handleClickLogout}
        >
          LOGOUT
        </button>
      </div>
    </div>
  );
};

export default LogoutComponent;
