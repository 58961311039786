//#화면이동 커스텀훅
//#버튼에 사용됨

import { useState } from "react";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { getStr } from "../util/stringUtil";

// 숫자 변환 (대체값 적용)
const getNum = (param, defaultValue) => {
  if (!param) {
    return defaultValue;
  }
  return parseInt(param);
};

const useCustomMove = () => {
  const navigate = useNavigate();

  // 현재 페이지 번호를 클릭해도 API 호출 가능하도록 하는 상태값
  // 이 moveToList 함수를 호출한 컴포넌트에 refresh 값을 전달하고
  // 전달받은 컴포넌트에서 useEffect에 의존 데이터로 사용한다.
  // 토글기능 , (refesh 값은 페이지번호를 클릭할 때 마다 매번 변경된다)
  // 그래서 현재 페이지에서 페이지번호를 클릭해도 다시 호출하게 된다.
  const [refresh, setRefresh] = useState(false);

  // URL 쿼리 파라미터 제어
  const [queryParams] = useSearchParams();
  const page = getNum(queryParams.get("page"), 1);
  const size = getNum(queryParams.get("size"), 10);
  const div = getStr(queryParams.get("div"), "notice");

  //page=1&size=10
  const queryDefault = createSearchParams({ page, size, div }).toString();

  // 페이지 이동할 때는 파라미터 정보(page, size)가 변경된다.
  // 그래서 아래 다른 move 함수보다 로직이 복잡하다.
  const moveToList = (pageParam) => {
    let queryStr = "";

    if (pageParam) {
      const div = pageParam.div ? pageParam.div : "notice";
      const pageNum = getNum(pageParam.page, 1);
      const sizeNum = getNum(pageParam.size, 100);

      queryStr = createSearchParams({
        page: pageNum,
        size: sizeNum,
        div: div,
      }).toString();
    } else {
      queryStr = queryDefault;
    }

    setRefresh(!refresh);

    console.log("^^queryStr: {}", queryStr);

    navigate({ pathname: "/", search: queryStr });
  };

  return { moveToList, page, size, div, refresh }; // refresh 추가
};

export default useCustomMove;
