import React, { useState } from "react";
import "./state-styles.modules.css";

const StateTtestApp = () => {
  const [animationState, setAnimationState] = useState("rolled-down");

  const toggleRoll = () => {
    setAnimationState("rolled-up"); // 말아올리는 애니메이션 시작
    setTimeout(() => {
      setAnimationState("rolled-down"); // 말아올린 후 풀리는 애니메이션 시작
    }, 1500); // 첫 번째 애니메이션(말아올림)이 끝난 후 풀리기 시작
  };

  return (
    <div className="test_app">
      <div className={`poster ${animationState}`} onClick={toggleRoll}></div>
      <button onClick={toggleRoll} className="toggle-button">
        Start Animation
      </button>
    </div>
  );
};

export default StateTtestApp;
