import { configureStore } from "@reduxjs/toolkit";
import loginSlice from "./slices/loginSlice";
import cartSlice from "./slices/cartSlice";
import memberSlice from "./slices/memberSlice";
import gameSlice from "./slices/gameSlice";
import gameControlSlice from "./slices/gameControlSlice";
import gameSetSlice from "./slices/gameSetSlice";

export default configureStore({
  reducer: {
    loginSlice: loginSlice, // 이거다 ==> export default loginSlice.reducer
    cartSlice: cartSlice, // 이거다 ==> export default cartSlice.reducer
    memberSlice: memberSlice, // 이거다 ==> export default memberSlice.reducer
    gameSlice: gameSlice,
    gameControlSlice: gameControlSlice,
    gameSetSlice: gameSetSlice,
  },
});
