// 날짜를 특정 형식으로 변환 (기본 형식: YYYY-MM-DD)
export const formatDate = (date, format = "YYYY-MM-DD") => {
  const d = new Date(date);
  const year = d.getFullYear();
  const month = `0${d.getMonth() + 1}`.slice(-2);
  const day = `0${d.getDate()}`.slice(-2);

  if (format === "YYYY-MM-DD") {
    return `${year}-${month}-${day}`;
  } else if (format === "DD/MM/YYYY") {
    return `${day}/${month}/${year}`;
  } else if (format === "MM-DD-YYYY") {
    return `${month}-${day}-${year}`;
  } else if (format === "YYYY.MM.DD") {
    return `${year}.${month}.${day}`;
  }

  return date.toString();
};

// 두 날짜 사이의 일 수 계산
export const daysBetween = (date1, date2) => {
  const d1 = new Date(date1);
  const d2 = new Date(date2);
  const diffTime = Math.abs(d2 - d1);
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};

// 시간차 구하기 (HH:mm:ss)
export const getTimeDifference = (start, end) => {
  // Date 객체로 변환
  const startDate = new Date(start);
  const endDate = new Date(end);

  // 시간 차이 계산 (밀리초 단위)
  const differenceInMillis = endDate - startDate;

  // 초, 분, 시 단위로 변환
  const seconds = Math.floor((differenceInMillis / 1000) % 60);
  const minutes = Math.floor((differenceInMillis / (1000 * 60)) % 60);
  const hours = Math.floor((differenceInMillis / (1000 * 60 * 60)) % 24);

  // 형식화된 문자열 반환
  return `${hours > 0 ? String(hours).padStart(2, "0") + ":" : ""}
  
  ${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
};

// 시간 차이 계산 (TimeStamp)
export const getTimeDifferenceTimeStamp = (start, end, startName, endName) => {
  const timestamp1 = start;
  const timestamp2 = end;

  const difference = timestamp2 - timestamp1;

  const diffInSeconds = Math.floor(difference / 1000);

  const minutes = Math.floor(diffInSeconds / 60);
  const seconds = diffInSeconds % 60;

  return `${minutes} ${startName} ${seconds} ${endName}`;
};

// 현재 날짜를 반환 (기본 형식: YYYY-MM-DD)
export const getCurrentDate = (format = "YYYY-MM-DD") => {
  return formatDate(new Date(), format);
};

// 주어진 날짜의 요일 반환 (0 = 일요일, 6 = 토요일)
export const getDayOfWeek = (date) => {
  const d = new Date(date);
  return d.getDay();
};

// 날짜 유효성 검사
export const isValidDate = (date) => {
  return !isNaN(Date.parse(date));
};
