import React, { startTransition, useEffect, useRef, useState } from "react";
import BasicLayout from "../../layout/BasicLayout";
import TypeIt from "typeit-react";
import { applyStyles } from "../../util/stringUtil";

const TypeItPage = () => {
  const txt =
    '조선의 <b>조라는 묘호 남용의 단초를 제공한 왕으로</b>, 아버지인 "세조"가 대단한 업적을 세웠다 볼 수 없었음에도 묘호(세조)를 강력히 주장했다.';
  const customStyle = { color: "blue", fontWeight: "bold" };
  const styledText = applyStyles(txt, customStyle);

  const handleCallback = () => {
    console.log("콜백 함수 호출");
    // 여기에 원하는 콜백 함수 로직을 작성하세요
  };

  const SuperStrong = ({ children }) => {
    return <strong style={{ color: "blue" }}>{children}</strong>;
  };

  return (
    <BasicLayout pageClassName="setting bg-map">
      <div>
        <div style={styles.default}>타이핑 테스트</div>

        <div style={styles.default}>
          <div style={styles.sub}>
            <TypeIt>Hello TypeIt</TypeIt>
          </div>

          <div style={styles.sub}>
            <TypeIt
              options={{
                strings:
                  "조선의 조라는 묘호 남용의 단초를 제공한 왕으로, <b>아버지인 세조가 대단한 업적을 세웠다</b> 볼 수 없었음에도 묘호(세조)를 강력히 주장했다.",
                speed: 50,
                afterComplete: () => {
                  setTimeout(() => {
                    handleCallback();
                  }, 3000); // 3초 후 콜백 호출
                },
              }}
            />
          </div>

          <div style={styles.sub}>
            <TypeIt>
              Weak text. <SuperStrong>Super strong text.</SuperStrong>
            </TypeIt>
          </div>

          <div style={styles.sub}>
            <TypeIt
              options={{
                strings: [styledText],
                speed: 150,
                breakLines: false,
                afterComplete: () => {},
              }}
            />
          </div>
        </div>
      </div>
    </BasicLayout>
  );
};

const styles = {
  default: {
    margin: "20px",
    padding: "20px",
    border: "1px solid #000",
  },
  sub: {
    margin: "20px",
    padding: "20px",
  },
  modal: {},
};

export default TypeItPage;
