import React, { Suspense } from "react";
import { Navigate } from "react-router-dom";

import LoadingComponent from "../components/common/LoadingComponent";
import ProductListPage from "../pages/store/ProductListPage";

const Loading = LoadingComponent;

const storeRouter = () => {
  return [
    {
      path: "",
      element: (
        <Suspense fallback={<Loading />}>
          <Navigate replace to="products" />
        </Suspense>
      ),
    },
    {
      path: "product",
      element: (
        <Suspense fallback={<Loading />}>
          <ProductListPage />
        </Suspense>
      ),
    },
  ];
};

export default storeRouter;
