import axios from "axios";
import { API_SERVER_HOST } from "./apiConfig";

const host = `${API_SERVER_HOST}/api`;

export const getHealth = async () => {
  try {
    const res = await axios.get(`${host}/health`, {
      // withCredentials: true,
    });
    return res;
  } catch (error) {
    console.log("^^ health - error : ", error);
    throw error; // ex) 'ERROR_ACCESSDENIED'
  }
};
