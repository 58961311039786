import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import useCustomLogin from "../../hooks/useCustomLogin";
import { getAccessToken, getMemberWithAccessToken } from "../../api/kakaoApi";
import { useDispatch } from "react-redux";
import { login } from "../../redux/slices/loginSlice";

const KakaoRedirectPage = () => {
  const [searchParams] = useSearchParams();

  const { moveToPath } = useCustomLogin();

  const dispatch = useDispatch();

  // 카카오에서 받은 인가 코드
  const authCode = searchParams.get("code");

  useEffect(() => {
    // 카카오에서 받은 인가 코드를 이용해 다시 카카오의 엑세스 토큰 받아옴 (accessToken)
    getAccessToken(authCode).then((accessToken) => {
      console.log("Kakao Access Token : ", accessToken);

      // 카카오에서 받은 accessToken 을 API 서버에 전달해서 사용자 정보를 얻어온다.
      // 이 함수를 호출한 결과는 클레임(MemberDTO>getClaims) 다
      getMemberWithAccessToken(accessToken).then((memberInfo) => {
        console.log("^^ memberInfo : ", memberInfo);
        try {
          // 소셜로그인은 reducers > login 리듀서를 사용한다. (extraReducers > loginPostAsync 사용안함)
          // 로그인을 비동기로 할 필요가 없다? 그래서 login 리듀서를 사용하면된다.
          dispatch(login(memberInfo));

          //소셜 회원이 아니라면
          if (memberInfo && !memberInfo.social) {
            moveToPath("/");
          } else {
            moveToPath("/my/profile");
          }
        } catch (error) {
          console.log("^^ error.message : ", error.message);
        }
      });
    });
  }, [authCode]);

  console.log("^^kakao-authCode : " + authCode);

  return (
    <div className="modal-popup loading">
      <div className="sword-img"></div>
      <ul className="loading-ber">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
  );
};

export default KakaoRedirectPage;
