import { Navigate } from "react-router-dom";
import Rank from "../pages/rank/RankPage";
import { Suspense } from "react";
import Loading from "../components/common/LoadingComponent";

const rankRouter = () => {
  return [
    // {
    //   path: "",
    //   element: <Navigate replace to="all" />,
    // },
    {
      path: ":t",
      element: (
        <Suspense fallback={<Loading />}>
          <Rank />
        </Suspense>
      ),
    },
  ];
};

export default rankRouter;
