import React, { Suspense } from "react";
import { Navigate } from "react-router-dom";
import LoadingComponent from "../components/common/LoadingComponent";
import DefaultPage from "../pages/setting/DefaultPage";

const settingRouter = () => {
  return [
    {
      path: "",
      element: (
        <Suspense fallback={<LoadingComponent />}>
          <Navigate replace to="default" />
        </Suspense>
      ),
    },
    {
      path: "default",
      element: (
        <Suspense fallback={<LoadingComponent />}>
          <DefaultPage />
        </Suspense>
      ),
    },
  ];
};

export default settingRouter;
