// 숫자를 원하는 자리수까지 반올림
export const roundTo = (num, decimals) => {
  const factor = 10 ** decimals;
  return Math.round(num * factor) / factor;
};

// 숫자를 통화 형식으로 변환 (기본적으로 미국 달러)
export const formatCurrency = (num, locale = "en-US", currency = "USD") => {
  return new Intl.NumberFormat(locale, { style: "currency", currency }).format(
    num
  );
};

// 천 단위 구분 쉼표 추가
export const numberWithCommas = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

// 범위 내의 랜덤 숫자 생성
export const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

// 소수점 자르기
export const truncateDecimal = (number, decimalPlaces) => {
  return parseFloat(number.toFixed(decimalPlaces));
};
