import { Navigate } from "react-router-dom";
import Notice from "../pages/support/NoticePage";
import { Suspense } from "react";
import LoadingComponent from "../components/common/LoadingComponent";

const Loading = LoadingComponent;

const supportRouter = () => {
  return [
    {
      path: "",
      element: <Navigate replace to="notice" />,
    },
    {
      path: "notice",
      element: (
        <Suspense fallback={<Loading />}>
          <Notice />
        </Suspense>
      ),
    },
  ];
};

export default supportRouter;
