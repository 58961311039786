import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCookie } from "../util/cookieUtil";
import { login, logout } from "../redux/slices/loginSlice";

const withLoginCheck = (WrappedComponent) => {
  return (props) => {
    const dispatch = useDispatch();
    const loginState = useSelector((state) => state.loginSlice);

    const refreshLoginState = useCallback(() => {
      const memberInfo = getCookie("member");

      if (memberInfo) {
        if (memberInfo.nickname) {
          memberInfo.nickname = decodeURIComponent(memberInfo.nickname);
        }

        console.log(
          "^^withLoginCheck : " +
            loginState.memberId +
            "#" +
            memberInfo.memberId
        );

        if (loginState.memberId !== memberInfo.memberId) {
          dispatch(login(memberInfo));
        }
      } else {
        dispatch(logout());
      }
    }, [dispatch, loginState.memberId]);

    useEffect(() => {
      console.log("^^withLoginCheck-refreshLoginState : ", refreshLoginState);
      refreshLoginState();
    }, [refreshLoginState]);

    return <WrappedComponent {...props} />;
  };
};

export default withLoginCheck;
