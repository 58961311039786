import React from "react";

const useCustomNative = () => {
  const native_vibrate = () => {
    if (window.ReactNativeWebView) {
      console.log("^^useCustomNative-vibrate()");
      window.ReactNativeWebView.postMessage("vibrate");
    }
  };

  const nativeGame = (data) => {
    if (window.ReactNativeWebView) {
      console.log("^^useCustomNative-typing()");
      window.ReactNativeWebView.postMessage(JSON.stringify(data));
    }
  };

  return { native_vibrate, nativeGame };
};

export default useCustomNative;
