import React, { useEffect, useState } from "react";
import { numberWithCommas } from "../../util/numberUtil";
import { API_SERVER_HOST } from "../../api/apiConfig";
import useCustomCart from "../../hooks/useCustomCart";
import useCustomLogin from "../../hooks/useCustomLogin";

import { useModal } from "../common/ModalContext";
import { postAdd } from "../../api/orderApi";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../../util/cookieUtil";
import { toast } from "react-toastify";

const host = API_SERVER_HOST;

const ProductRowComponent = ({ data }) => {
  const { product, isOrder } = data;

  console.log("^^ProductRowComponent-data : ", data);

  const navigate = useNavigate();

  const { isLogin, loginState, moveToLogin } = useCustomLogin();

  console.log("^^Product-isLogin,loginState : ", isLogin, loginState);

  const { cartItems, changeCart } = useCustomCart();
  console.log("^^ Product-cartItems : ", cartItems);

  const [isNewItems, setIsNewItems] = useState(false);

  const [productIdx, setProductIdx] = useState(0);

  const { openModal } = useModal();

  const handleClickAddCart = async (pidx) => {
    // 로그인 체크 (로그인후 구매가능)
    if (!getCookie("member")) {
      modalLogin();
      return;
    }

    if (isOrder) {
      modalAlreadyOrder();
      return;
    }

    setProductIdx(pidx);
    console.log("^^pidx:", pidx);

    let qty = 1;

    const params = {
      memberId: loginState.memberId,
      productIdx: pidx,
      qty: qty,
    };

    console.log("----- AA cartItems ", cartItems);

    const addedItem = cartItems.filter(
      (item) => item.productIdx === parseInt(pidx)
    )[0];

    // 장바구니에 아이템이 있으면 주문, 없으면 장바구니에 아이템 추가(changeCart)
    if (addedItem) {
      // 수량 변경 필요 없어, 주석처리(사용안함)
      // if (window.confirm("이미 추가된 상품입니다. 추가하시겠습니까?") === false) {
      //   return;
      // }
      // qty = addedItem.qty + 1;

      // 구매하기
      buyProduct(addedItem);
      return;
    } else {
      try {
        await changeCart(params); // 장바구니에 해당 아이템을 추가한다. (redux 상태관리된다)

        setIsNewItems(true); // 아이템이 새로 추가된 경우 신규 아이템이라고 설정한다.

        console.log("-----xxxx2 ", cartItems);

        return;
      } catch (error) {
        console.error("Error updating cart:", error);
        toast.error(error, { theme: "dark" });
      }
    }
  };

  // 장바구니 아이템 신규 등록된 경우 장바구니의 아이템중에 해당 상품을 찾아 주문한다.
  // 카트 아이템, 상품번호가 변경, 장바구니 생성된 경우 동작한다.
  // 이미 장바구니가 생성된 경우 동작하지 않음.
  useEffect(() => {
    console.log(
      "-- new isNewItems, cartItems, productIdx  : ",
      isNewItems,
      cartItems,
      "#####" + productIdx
    );

    if (isNewItems && cartItems != null) {
      const changedItem = cartItems.filter(
        (item) => item.productIdx === parseInt(productIdx)
      )[0];

      console.log("^^changedItem ", changedItem);

      if (changedItem) {
        buyProduct(changedItem); // 주문 Modal 호출
      }
    }
  }, [cartItems, productIdx, isNewItems]);

  const buyProduct = (item) => {
    console.log("-- 구매하기 item : ", item);
    openModal({
      type: "ADVANCED",
      title: item.name,
      content: item.name + "을 구매하시겠습니까?",
      content2: "구매금액 : " + numberWithCommas(item.price) + "원",
      onConfirmText: "구매할게요",
      onCloseText: "아니오",
      onConfirm: () => {
        console.log("^^orderData: ", orderData);

        postAdd(orderData).then((response) => {
          console.log("resposne.data.result : ", response.data.result);
          response.data.result > 0 &&
            navigate({ pathname: "/main/game-list" }, { replace: true });
        });
      },
      onClose: () => null,
    });
  };

  const orderData = {
    cartItems: cartItems,
    memberId: loginState.memberId,
    memberIdx: loginState.memberIdx,
  };

  const modalAlreadyOrder = () => {
    console.log("-- 이미 주문 모달 --");
    openModal({
      type: "SIMPLE",
      content: "이미 구매했어요.",
      onClose: () => navigate({ pathname: "/" }, { replace: true }),
    });
  };

  const modalLogin = () => {
    console.log("-- 로그인 모달 --");
    openModal({
      type: "SIMPLE",
      content: "로그인을 해주세요",
      onClose: () => moveToLogin(),
    });
  };

  return (
    <div className="goods">
      <div className="box">
        <div className="gd-name">{product.name}</div>
        <div className="info">
          <div className="thum">
            <img
              alt=""
              src={`${host}/api/file/product/s_${product.productImageList[0]}`}
              type="image/webp"
              loading="lazy"
              crossOrigin="anonymous"
            />
          </div>
          <div className="buy">
            <div className="detail">
              <p>{product.description}</p>
            </div>
            <div className="gd-price">
              <p>
                <em></em>
                {numberWithCommas(product.price)}
              </p>
              <button
                className="buy-btn"
                onClick={() => handleClickAddCart(product.productIdx)}
              >
                {!isOrder ? "구매하기" : "구매완료"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProductRowComponent;
