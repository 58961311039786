import React, { useEffect } from "react";
import BasicLayout from "../../layout/BasicLayout";
import IndexComponent from "../../components/game/IndexComponent";
import { useNavigate, useParams } from "react-router-dom";
import useCustomLogin from "../../hooks/useCustomLogin";
import { useDispatch } from "react-redux";
import { resetMemberState } from "../../redux/slices/memberSlice";

const IndexPage = () => {
  const { isLogin, loginState, moveToLogin } = useCustomLogin();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { pridx } = useParams();
  const memberId = loginState.memberId;

  useEffect(() => {
    if (!isLogin) {
      console.log("^^Game-IndexPage isLogin : ", isLogin);
      dispatch(resetMemberState());
      moveToLogin();
      return;
    }

    if (!pridx) {
      navigate({ pathname: "/main/game-list" }, { replace: true });
      return;
    }
  }, [pridx, memberId, navigate, dispatch, isLogin, moveToLogin]);

  return (
    <BasicLayout pageClassName="ready home">
      <div className="content ready-content">
        <IndexComponent pridx={pridx} />
      </div>
    </BasicLayout>
  );
};

export default IndexPage;
