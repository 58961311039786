import React, { useEffect, useState } from "react";
import useCustomNative from "../../hooks/useCustomNative";

const DefaultComponent = () => {
  const { nativeGame } = useCustomNative();

  const [isBgm, setIsBgm] = useState(false);

  const handleBgmChange = (e) => {
    setIsBgm(e.target.checked);
  };

  useEffect(() => {
    nativeGame({ type: "sound_typing", value1: isBgm ? "Y" : "N", value2: "" }); // 사운드 off(RN)
  }, [isBgm]);

  return (
    <div className="set-info roll">
      <div className="inner">
        <div className="set">
          <div className="op sound">
            <h3>사운드</h3>
            <input
              type="checkbox"
              name="bgm"
              value="true"
              id="s-onoff"
              checked={isBgm}
              onChange={handleBgmChange}
            />
            <label htmlFor="s-onoff">
              <div className="icon-box"></div>
            </label>
            <label htmlFor="s-onoff">
              <div className="on-off">
                <em></em>
              </div>
            </label>
          </div>
          <div className="op voice">
            <h3>음성지원</h3>
            <input type="checkbox" name="" id="v-onoff" />
            <label htmlFor="v-onoff">
              <div className="icon-box"></div>
            </label>
            <label htmlFor="v-onoff">
              <div className="on-off">
                <em></em>
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DefaultComponent;
