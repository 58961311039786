import React, { useEffect, useState } from "react";

import { shallowEqual, useDispatch, useSelector } from "react-redux";

const PointComponent = () => {
  console.log("-----PointComponent : ");

  const [isOpen, setIsOpen] = useState(false);

  const optionCount = useSelector(
    (state) => state.gameControlSlice.optionCount,
    shallowEqual
  );

  useEffect(() => {
    if (optionCount > 0) {
      setIsOpen(true);

      const timer = setTimeout(() => {
        setIsOpen(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [optionCount]);

  return (
    <div className={`damage dmg-point ${isOpen ? "open" : ""}`}>
      <p>- 20</p>
    </div>
  );
};

export default PointComponent;
