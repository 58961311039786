import { Navigate } from "react-router-dom";
import Profile from "../pages/my/ProfilePage";
import Record from "../pages/my/RecordPage";
import OrderHistory from "../pages/my/OrderHistoryPages";
import { Suspense } from "react";
import Loading from "../components/common/LoadingComponent";

const myRouter = () => {
  return [
    {
      path: "",
      element: <Navigate replace to="profile" />,
    },
    {
      path: "profile",
      element: (
        <Suspense fallback={<Loading />}>
          <Profile />
        </Suspense>
      ),
    },
    {
      path: "record",
      element: (
        <Suspense fallback={<Loading />}>
          <Record />
        </Suspense>
      ),
    },
    {
      path: "history",
      element: (
        <Suspense fallback={<Loading />}>
          <OrderHistory />
        </Suspense>
      ),
    },
  ];
};

export default myRouter;
