import GameListComponent from "../../components/main/GameListComponent";
import BasicLayout from "../../layout/BasicLayout";

const GameListPage = () => {
  return (
    <BasicLayout pageClassName="home bg-map">
      <div className="content home-content">
        <h2 className="tit">Aha King</h2>
        <GameListComponent />
      </div>
    </BasicLayout>
  );
};

export default GameListPage;
