import { useDispatch, useSelector } from "react-redux";
import {
  getCartItemsAsync,
  postChangeCartAsync,
} from "../redux/slices/cartSlice";

const useCustomCart = () => {
  // state 는 전체 상태, state.cartSlice 는 cartSlice 리듀서가 관리하는 상태 (initState)
  const cartItems = useSelector((state) => state.cartSlice);

  const dispatch = useDispatch();

  const refreshCart = () => {
    console.log("^^ ----------- Cart refreshCart ");
    dispatch(getCartItemsAsync());
  };

  const changeCart = (param) => {
    dispatch(postChangeCartAsync(param));
  };

  return { cartItems, refreshCart, changeCart };
};

export default useCustomCart;
