import { Navigate } from "react-router-dom";
import GameListPage from "../pages/main/GameListPage";
import { Suspense } from "react";
import LoadingComponent from "../components/common/LoadingComponent";
const Loading = LoadingComponent;

const mainRouter = () => {
  return [
    {
      path: "game-list",
      element: (
        <Suspense fallback={<Loading />}>
          <GameListPage />
        </Suspense>
      ),
    },
    {
      path: "",
      element: <Navigate replace to="game-list" />,
    },
  ];
};

export default mainRouter;
