// 쿠키 저장/조회/삭제 함수

import { Cookies } from "react-cookie";

const cookies = new Cookies();

// 쿠키생성: 로그인 한 정보를 쿠키에 담는다.
// 파라미터: 이름, 값, 시간
export const setCookie = (name, value, days) => {
  // 날짜 : 자바스크립트의 현재 날짜에 더한다.
  const expires = new Date();

  // 일 단위
  expires.setUTCDate(expires.getUTCDate() + days);

  // 초 단위 (테스트시에만 사용함)
  //expires.setUTCSeconds(expires.getUTCSeconds() + days);

  console.log("Expires:", expires.toUTCString()); // 확인용 로그
  return cookies.set(name, value, { path: "/", expires: expires });
};

// 쿠키 가져오기
export const getCookie = (name) => {
  return cookies.get(name);
};

// 쿠키 삭제 (path 경로에 있는 쿠키를 지워라)
export const removeCookie = (name, path = "/") => {
  cookies.remove(name, { path });
};
