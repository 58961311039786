// 문자열을 대문자로 변환
export const toUpperCase = (str) => str.toUpperCase();

// 문자열을 소문자로 변환
export const toLowerCase = (str) => str.toLowerCase();

// 문자열의 첫 글자만 대문자로 변환
export const capitalize = (str) => {
  if (!str) return "";
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

// 문자열을 특정 길이로 자르고 뒤에 ...을 붙임
export const truncate = (str, length, isAddDot) => {
  if (str.length <= length) return str;
  return str.slice(0, length) + (isAddDot ? "..." : "");
};

// 공백 제거
export const trim = (str) => str.trim();

// 문자열이 비어있는지 확인
export const isEmpty = (str) => !str || str.trim().length === 0;

export const getStr = (param, defaultValue) => {
  if (!param) {
    return defaultValue;
  }
  return param;
};

// 줄바꿈
export const decodeHtml = (html) => {
  const textArea = document.createElement("textarea");
  textArea.innerHTML = html;
  return textArea.value;
};

/**
 * 문자열에 쌍따옴표 안의 문자열에 스타일 효과를 준다.
 * 사용방법 : applyStyles(txt,  { color: 'blue', fontWeight: 'bold' });
 */
export const applyStyles = (text, style) => {
  // 스타일 객체를 문자열로 변환
  const styleString = Object.entries(style)
    .map(([key, value]) => `${key}: ${value}`)
    .join("; ");

  // 정규 표현식을 사용하여 쌍따옴표로 묶인 부분을 찾음
  const regex = /"(.*?)"/g;
  // 쌍따옴표로 묶인 문자열에 스타일을 적용한 HTML 문자열로 변환
  return text.replace(regex, (match, p1) => {
    return `" <span style="${styleString}">${p1}</span> "`;
  });
};
