import { lazy, Suspense } from "react";
import { createBrowserRouter } from "react-router-dom";
import mainRouter from "./mainRouter";
import memberRouter from "./memberRouter";
import LoadingComponent from "../components/common/LoadingComponent";
import myRouter from "./myRouter";
import storeRouter from "./storeRouter";
import StateTtestApp from "../test/ani_slider/StateTestApp";
import rankRouter from "./rankRouter";
import supportRouter from "./supportRoute";
import settingRouter from "./settingRouter";
import gameRouter from "./gameRouter";
import zzRouter from "./zzRouter";

const Loading = LoadingComponent;
const MainIndex = lazy(() => import("../pages/main/IndexPage"));
const RankIndex = lazy(() => import("../pages/rank/IndexPage"));
const IntroIndex = lazy(() => import("../pages/main/IntroPage"));
const SupportIndex = lazy(() => import("../pages/support/IndexPage"));
const ZzIndex = lazy(() => import("../pages/zz/IndexPage"));

const root = createBrowserRouter([
  // 애플리케이션의 라우팅을 설정
  {
    path: "",
    element: (
      <Suspense fallback={<Loading />}>
        <IntroIndex />
      </Suspense>
    ),
  },
  {
    path: "main",
    children: mainRouter(),
  },
  {
    path: "member",
    children: memberRouter(),
  },
  {
    // 1. 초기 경로: 사용자가 /my로 이동하면 MyIndex를 둘러싼 Suspense로 인해 IndexPage 구성 요소가 표시.
    // 2. 리디렉션: myRouter 내부의 빈 경로 ""는 /my/profile로 리디렉션.
    // 3. 하위 구성 요소 렌더링: 선택한 탭에 따라 해당 구성 요소(<Profile />, <Record /> , <OrderHistory />)가 <Outlet / > IndexPage`의 영역.
    path: "my",
    children: myRouter(), //  /my의 하위 경로 가져옴.
  },
  {
    path: "rank",
    children: rankRouter(),
  },
  {
    path: "store",
    children: storeRouter(),
  },
  // 1. 초기 경로: 사용자가 /support 로 이동하면 SupportIndex 를 둘러싼 Suspense로 인해 IndexPage 구성 요소가 표시.
  // 2. 리디렉션: supportRouter 내부의 빈 경로 ""는 /support/notice 로 리디렉션.
  // 3. 하위 구성 요소 렌더링: 선택한 탭에 따라 해당 구성 요소(<notice />, <read /> , <modify />)가 <Outlet / > IndexPage`의 영역.
  {
    path: "support",
    element: (
      <Suspense fallback={<Loading />}>
        <SupportIndex />
      </Suspense>
    ),
    children: supportRouter(),
  },
  {
    path: "setting",
    children: settingRouter(),
  },
  {
    path: "game",
    children: gameRouter(),
  },
  {
    path: "zz",
    children: zzRouter(),
  },
  {
    path: "test",
    element: <StateTtestApp />,
  },
]);

export default root;
