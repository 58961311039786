//import LoadingComponent from "../components/common/LoadingComponent";
import React, { useEffect, useState } from "react";
import { ModalProvider, useModal } from "../components/common/ModalContext";
import QuickComponent from "../components/common/QuickComponent";
import BasicMenu from "../menu/BasicMenu";
import BasicFooter from "./BasicFooter";
import { useLocation } from "react-router-dom";

const BasicMenuMemo = React.memo(() => {
  return <BasicMenu />;
});

const QuickComponentMemo = React.memo(() => {
  return <QuickComponent />;
});

// const useNativeParam = () => {
//   const [isNativeApp, setIsNativeApp] = useState(false);
//   const location = useLocation();

//   useEffect(() => {
//     const params = new URLSearchParams(location.search);
//     const ntvParam = params.get("ntv");

//     if (ntvParam === "y") {
//       setIsNativeApp(true);
//       localStorage.setItem("isNativeApp", "y");
//     } else {
//       const storedIsNativeApp = localStorage.getItem("isNativeApp");
//       setIsNativeApp(storedIsNativeApp === "y");
//     }
//   }, [location]);

//   return isNativeApp;
// };

const BasicLayout = ({ children, pageClassName }) => {
  //const isNativeApp = useNativeParam();
  return (
    <ModalProvider>
      <div className={`container ${pageClassName}`}>
        <div className="inner">
          {/* {!isNativeApp && <BasicMenuMemo />} */}
          <BasicMenuMemo />

          {children}
        </div>

        <QuickComponentMemo />

        {/* 로딩 컴포넌트 */}
        {/* <LoadingComponent /> */}

        {/* 확인 컴포넌트 */}
        {/* <ConfirmComponent /> */}
        {/* <BasicFooter /> */}
      </div>
    </ModalProvider>
  );
};

export default BasicLayout;
