import React from "react";

const LoadingComponent = () => {
  return (
    <div className="modal-popup loading">
      <div className="sword-img"></div>
      <ul className="loading-ber">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
  );
};

export default LoadingComponent;
