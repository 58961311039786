import React from "react";
import BasicLayout from "../../layout/BasicLayout";
import ProductListComponent from "../../components/store/ProductListComponent";

const ProductListPage = () => {
  return (
    <BasicLayout pageClassName="store">
      <div className="content store-content">
        <div className="front">
          <div className="tit">
            <div>
              <h2>상점</h2>
              <p>다양한 왕들을 만나보세요.</p>
            </div>
          </div>
        </div>

        <ProductListComponent />
      </div>
    </BasicLayout>
  );
};

export default ProductListPage;
