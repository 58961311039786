import { Link, useParams } from "react-router-dom";
import RankComponent from "../../components/rank/RankComponent";
import BasicLayout from "../../layout/BasicLayout";

const RankPage = () => {
  const { t } = useParams();

  return (
    <BasicLayout pageClassName="ranking bg-house">
      <div className="content ranking-content">
        <div className="list-st rk-list">
          <ul className="tabs">
            <li className={t === "all" ? "on" : ""}>
              <Link to="/rank/all">전체</Link>
            </li>
            <li className={t === "month" ? "on" : ""}>
              <Link to="/rank/month">월간</Link>
            </li>
            <li className={t === "week" ? "on" : ""}>
              <Link to="/rank/week">주간</Link>
            </li>
            <li className={t === "today" ? "on" : ""}>
              <Link to="/rank/today">오늘</Link>
            </li>
          </ul>

          <RankComponent t={t} />
        </div>
      </div>
    </BasicLayout>
  );
};

export default RankPage;
