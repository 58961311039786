import React from "react";
import BasicLayout from "../../layout/BasicLayout";
import DefaultComponent from "../../components/setting/DefaultComponent";

const DefaultPage = () => {
  return (
    <BasicLayout pageClassName="setting bg-map">
      <div className="content setting-content">
        <h2 className="tit">설정</h2>

        <DefaultComponent />
      </div>
    </BasicLayout>
  );
};

export default DefaultPage;
