import React, { startTransition, useEffect, useState } from "react";
import BasicLayout from "../../layout/BasicLayout";
import { getHealth } from "../../api/commonApi";
import { motion } from "framer-motion";
import axios from "axios";
import { API_SERVER_HOST } from "../../api/apiConfig";

const host = `${API_SERVER_HOST}`;

const IndextPage = () => {
  const [healthInfo, setHealthInfo] = useState("");

  const [lang, setLang] = useState("");

  useEffect(() => {
    const startTime = performance.now(); // 시작 시간 측정

    currentLang(); // 현재 locale 정보

    // ^^비동기 작업 이슈
    // 라우터에 <Suspense> 또는 아래와 같이 startTransition 으로 랩핑한다.
    // startTransition : 동기식 입력처리와 동시에 백그라운드에서 비동기 작업 수행함.
    startTransition(() => {
      getHealth()
        .then((response) => {
          const endTime = performance.now(); // 종료 시간 측정
          const responseTime = (endTime - startTime).toFixed(3); // 응답 시간 계산
          console.log(`rs: ${responseTime} ms`);
          setHealthInfo(`rs: ${responseTime} ms`);
        })
        .catch((error) => {
          setHealthInfo("Error fetching health:", error);
          console.error("Error fetching health:", error);
        });
    });
  }, []);

  const [isClicked, setIsClicked] = useState(false);

  const changeLang = async (lang) => {
    console.log("^^changeLang lang : ", lang);

    const res = await axios.get(`${host}/api/lang/changeLang?lang=${lang}`, {
      withCredentials: true,
    });
    console.log("^^changeLang res : ", res.data);
  };

  const currentLang = async () => {
    const res = await axios.get(`${host}/api/lang/info`, {
      withCredentials: true,
    });
    setLang(res.data);
    console.log("currentLang res : ", res.data);
  };

  return (
    <BasicLayout pageClassName="setting bg-map">
      <div style={styles.default}>
        <div>response time : {healthInfo}</div>
      </div>

      <div style={styles.default}>
        <div>
          시스템 정보 : {process.env.REACT_APP_ENV} <br />
          <br />
        </div>
        <div>
          REACT_APP_CLIENT_HOST : {process.env.REACT_APP_CLIENT_HOST} <br />
          <br />
          REACT_APP_API_SERVER_HOST : {
            process.env.REACT_APP_API_SERVER_HOST
          }{" "}
          <br />
          <br />
          REACT_APP_KAKAO_REDIRECT_URL :{" "}
          {process.env.REACT_APP_KAKAO_REDIRECT_URL} <br />
          REACT_APP_GOOGLE_REDIRECT_URI :{" "}
          {process.env.REACT_APP_GOOGLE_REDIRECT_URI} <br />
          REACT_APP_NAVER_REDIRECT_URI :{" "}
          {process.env.REACT_APP_NAVER_REDIRECT_URI} <br />
        </div>
      </div>

      <div style={{ margin: 30, padding: 30 }}>
        <h1>Locale 변경하기 --- 현재 Locale : {lang !== null && lang} </h1>
        <button onClick={() => changeLang("ko")}>KO 변경</button>
        <button onClick={() => changeLang("en")}>EN 변경</button>
      </div>

      <div style={{ margin: 30, padding: 30 }}>
        <a href={`${host}/api/lang/info`} target="_blank">
          Lang
        </a>
        #
        <a href={`${host}/api/lang/changeLang?lang=en`} target="_blank">
          Change Lang
        </a>
      </div>

      <div style={{ marginTop: "100px", width: 200, height: 200 }}>
        <motion.img
          src="/img/face/basic.jpg"
          alt="basic"
          className="background"
          initial={{ opacity: 0 }} // 애니메이션 시작 시 투명
          animate={{ opacity: 1 }} // 애니메이션 진행 중 투명도 증가
          transition={{ duration: 2 }} // 2초 동안 애니메이션
          style={{
            width: "100%",
            height: "100vh",
            backgroundImage: "linear-gradient(90deg, #ff6f61, #d4a5a5)",
          }}
        />
      </div>
    </BasicLayout>
  );
};

const styles = {
  default: {
    margin: "20px",
    padding: "20px",
    border: "1px solid #000",
  },
  modal: {},
};

export default IndextPage;
