import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCartItems, postChangeCart } from "../../api/cartApi";

// 언더바(_)는 createAsyncThunk 는 두개의 매개변수를 받는데 첫번째 매개변수인 파라미터는 사용하지 않는다는 의미다(_)
export const getCartItemsAsync = createAsyncThunk("getCartItemsAsync", () => {
  return getCartItems(); // 리턴하는 데이터가 payload 다. 초기값 형태로 만든다. ==> const initState = []
});

// 주의!!! postChangeCart(param) 이 비동기 함수라도 여기서 async ~ await 명시적으로 넣어주어야 한다???
export const postChangeCartAsync = createAsyncThunk(
  "postCartItemsAsync",
  async (param) => {
    return await postChangeCart(param);
  }
);

const initState = [];

// createSlice : 초기상태(state)와 각 action 에 대한 reducer 함수를 자동으로 생성해준다.

const cartSlice = createSlice({
  name: "cartSlice",
  initialState: initState,
  extraReducers: (builder) => {
    builder
      .addCase(getCartItemsAsync.fulfilled, (state, action) => {
        console.log("getCartItemsAsync fullfilled: ", state, action);
        // createAsyncThunk 에서 리턴한 데이터가 action.playload 가 된다. ==> return [];
        return action.payload; // return 키워드 때문에 initState 초기값이 완전 대체 (API 에서 반환된 데이타/형식 그대로 완전 대체됨)
      })
      .addCase(getCartItemsAsync.rejected, (state, action) => {
        console.log("getCartItemsAsync rejected : ", state, action);
        state.error = action.payload.error;
      })
      .addCase(postChangeCartAsync.fulfilled, (state, action) => {
        console.log("postChangeCartAsync fullfilled : ", state, action);
        return action.payload;
        // return state.map((item) =>
        //   item.productIdx === action.payload.productIdx ? action.payload : item
        // );
      })
      .addCase(postChangeCartAsync.rejected, (state, action) => {
        console.log("postChangeCartAsync rejected : ", state, action);
      });
  },
});

export default cartSlice.reducer;
