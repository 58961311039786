// 인가 요청 URL  ==> https://accounts.google.com/o/oauth2/v2/auth?client_id=${REST_API_KEY}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=email profile openid
import axios from "axios";
import { API_SERVER_HOST } from "./apiConfig";

// client id : [내 애플리케이션] > [앱 키] > REST API 키
const rest_api_key =
  "246678358567-9fl0vejof3bmqfsneojc0lb0l6dllt2b.apps.googleusercontent.com";

const rest_api_secret = process.env.REACT_APP_GOOGLE_REST_API_KEY;

const redirect_uri = process.env.REACT_APP_GOOGLE_REDIRECT_URI;

// 구글 인가 코드 요청 URL
const auth_code_path = process.env.REACT_APP_GOOGLE_AUTH_CODE_PATH;

// 구글 엑세스 엑세스 토큰 요청 URL (Access Token)
const access_token_url = process.env.REACT_APP_GOOGLE_ACCESS_TOKEN_URL;

// 구글 로그인 링크를 동적으로 생성
export const getGoogleLoginLink = () => {
  const googleURL = `${auth_code_path}?client_id=${rest_api_key}&redirect_uri=${redirect_uri}&response_type=code&scope=email profile openid`;

  return googleURL;
};

// [구글] 엑세스 토큰 요청하는 함수 (파라미터는 구글에서 받은 인가코드다)
// 이 단계까지 진행되어야 마지막 단계인 액세스 토큰을 가지고 사용자 정보를 얻어오는 단계로 넘어갈 수 있다.
export const getAccessToken = async (authCode) => {
  // 헤더 설정
  const header = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  };

  // 구글 Client Secret 사용안함 (만일 사용할 경우 파라미터에 client_secret 추가)
  // 구글 엑세스 토큰 만료시간은 1시간.
  // 그래서 리프래쉬 토큰과 함께 사용하지 않으면 쓸모가 없다.
  const params = {
    grant_type: "authorization_code",
    client_id: rest_api_key,
    client_secret: rest_api_secret,
    redirect_uri: redirect_uri,
    code: authCode,
  };

  const res = await axios.post(access_token_url, params, header);

  const accessToken = res.data.access_token;

  console.log("구글 토큰정보(res.data) : ", res.data);

  return accessToken;
};

// 서버에서 구글 사용자 정보 가져온다. (구글에서 발급 받은 토큰 사용)
export const getMemberWithAccessToken = async (accessToken) => {
  const res = await axios.get(
    `${API_SERVER_HOST}/api/member/auth/google?accessToken=${accessToken}`
  );
  return res.data;
};
