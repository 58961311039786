// 인가 요청 URL  ==> https://kauth.kakao.com/oauth/authorize?response_type=code&client_id=${REST_API_KEY}&redirect_uri=${REDIRECT_URI}

import axios from "axios";
import { API_SERVER_HOST } from "./apiConfig";

// client id : [내 애플리케이션] > [앱 키] > REST API 키
// 로컬pc용 : 7620650e153e8077e360aa8dc353c961, 아하킹 test용 : 4268e27e547966d1eaca83af37fc80f2
const rest_api_key = process.env.REACT_APP_KAKAO_REST_API_KEY;

// 로컬pc용: http://localhost:4000/member/kakao, 아하킹 test용 : http://test.ahaking.com:4000/member/kakao
const redirect_uri = process.env.REACT_APP_KAKAO_REDIRECT_URL;

// 카카오 인가 코드 요청 URL (https://kauth.kakao.com/oauth/authorize)
const auth_code_path = process.env.REACT_APP_KAKAO_AUTH_CODE_PATH;

// 카카오 엑세스 엑세스 토큰 요청 URL (Access Token)
const access_token_url = process.env.REACT_APP_KAKAO_ACCESS_TOKEN_URL;

// 카카오 로그인 링크를 동적으로 생성
export const getKakaoLoginLink = () => {
  const kakaoURL = `${auth_code_path}?client_id=${rest_api_key}&redirect_uri=${redirect_uri}&response_type=code`;

  return kakaoURL;
};

// [카카오] 엑세스 토큰 요청하는 함수 (파라미터는 카카오에서 받은 인가코드다)
// 이 단계까지 진행되어야 마지막 단계인 액세스 토큰을 가지고 사용자 정보를 얻어오는 단계로 넘어갈 수 있다.
export const getAccessToken = async (authCode) => {
  // 헤더 설정
  const header = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  };

  // 카카오 Client Secret 사용안함 (만일 사용할 경우 파라미터에 client_secret 추가)
  // 카카오 엑세스 토큰 만료시간은 1분이다. ==> 틀렷다. 6시간이다.
  // 그래서 리프래쉬 토큰과 함께 사용하지 않으면 쓸모가 없다.
  const params = {
    grant_type: "authorization_code",
    client_id: rest_api_key,
    redirect_uri: redirect_uri,
    code: authCode,
  };

  const res = await axios.post(access_token_url, params, header);

  const accessToken = res.data.access_token;

  console.log("카카오 토큰정보(res.data) : ", res.data);

  return accessToken;
};

export const getMemberWithAccessToken = async (accessToken) => {
  try {
    const res = await axios.get(
      `${API_SERVER_HOST}/api/member/auth/kakao?accessToken=${accessToken}`
    );
    return res.data;
  } catch (error) {
    // 네트워크 오류, 서버 오류 등 다양한 에러를 처리합니다.
    if (error.response) {
      // 요청이 이루어졌고, 서버가 상태 코드로 응답함
      console.error("Server responded with status:", error.response.status);
      console.error("Response data:", error.response.data);
      throw new Error(
        `서버 오류: ${error.response.status} - ${
          error.response.data.message || "Unknown error-!"
        }`
      );
    } else if (error.request) {
      // 요청이 이루어졌으나 응답이 없음
      console.error("No response received:", error.request);
      throw new Error("서버에 응답이 없습니다. 네트워크 상태를 확인해주세요.");
    } else {
      // 오류를 발생시킨 요청 설정 문제
      console.error("Error setting up request:", error.message);
      throw new Error("요청 설정 중 오류가 발생했습니다.");
    }
  }
};
