import React, { useEffect, useState } from "react";
import { decodeHtml, truncate } from "../../util/stringUtil";
import { useModal } from "../common/ModalContext";
import { getOne } from "../../api/boardApi";

const BoardRowComponent = ({ data }) => {
  const { openModal } = useModal();

  const [isModalOpen, setIsModalOpen] = useState(false);

  console.log("^^BoardRowComponent-data : ", data);

  const modalNotice = () => {
    getOne(data.boardIdx).then((respone) => {
      const decodedContent = decodeHtml(respone);

      console.log("^^boardContent : ", decodedContent, " # ", respone);

      console.log("-- 공지 상세 --");
      openModal({
        type: "NOTICE",
        title: data.title,
        content: decodedContent,
        onClose: () => handleCloseModal(),
      });
    });
  };

  const handleClickModal = (e) => {
    e.preventDefault();
    setIsModalOpen(true);
  };

  const handleCloseModal = (e) => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (isModalOpen) {
      modalNotice();
    }
  }, [isModalOpen]);

  return (
    <li>
      <a
        href="#!"
        onClick={handleClickModal}
        role="button"
        aria-label="Do something"
      >
        <p className="date">{truncate(data.createTime, 10, false)}</p>
        <span className="nt-tit">{data.title}</span>
      </a>
    </li>
  );
};

export default BoardRowComponent;
