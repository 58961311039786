import React from "react";

import { numberWithCommas } from "../../util/numberUtil";
import { formatDate } from "../../util/dateUtil";

const RecordRowComponent = ({ data }) => {
  console.log("^^data : ", data);

  const createDateTime = data.createTime.split(".")[0];
  const updateDateTime = data.updateTime.split(".")[0];

  const gameDate = formatDate(createDateTime, "YYYY.MM.DD");

  const start = new Date(createDateTime);
  const end = new Date(updateDateTime);

  const formattedStartTime = `${String(start.getHours()).padStart(
    2,
    "0"
  )}:${String(start.getMinutes()).padStart(2, "0")}:${String(
    start.getSeconds()
  ).padStart(2, "0")}`;
  const formattedEndTime = `${String(end.getHours()).padStart(2, "0")}:${String(
    end.getMinutes()
  ).padStart(2, "0")}:${String(end.getSeconds()).padStart(2, "0")}`;

  return (
    <ul className="tr">
      <li className="td time">
        <p>
          {gameDate}
          <br />
          {formattedStartTime}~{formattedEndTime}
        </p>
      </li>
      <li className="td lv">
        <p>조선{data.gameCount + 1}</p>
      </li>
      <li className="td point">
        <p>{numberWithCommas(numberWithCommas(data.totalPoint))}</p>
      </li>
      <li className="td coin">
        <p>{data.hudo}</p>
      </li>
    </ul>
  );
};

export default RecordRowComponent;
