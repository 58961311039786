// 인가 요청 URL  ==> https://nid.naver.com/oauth2.0/authorize?client_id=${REST_API_KEY}&redirect_uri=${REDIRECT_URI}&response_type=code&state=uuidv4()
import axios from "axios";
import { API_SERVER_HOST } from "./apiConfig";
import { v4 as uuidv4 } from "uuid";

const state_code = uuidv4();

// client id : [내 애플리케이션] > 애플리케이션 정보 > Client ID
const rest_api_key = process.env.REACT_APP_NAVER_REST_API_KEY;

const rest_api_secret = process.env.REACT_APP_NAVER_REST_API_SECRET;

const redirect_uri = process.env.REACT_APP_NAVER_REDIRECT_URI;

// 네이버 인가 코드 요청 URL
const auth_code_path = process.env.REACT_APP_NAVER_AUTH_CODE_PATH;

// 네이버 엑세스 엑세스 토큰 요청 URL (Access Token)
const access_token_url = process.env.REACT_APP_NAVER_ACCESS_TOKEN_URL;

// 네이버 엑세스 엑세스 토큰 서버로 요청 URL (Access Token)
const server_access_token_url = `${API_SERVER_HOST}/api/member/auth/naver`; // /naver-token

// 네이버 로그인 링크를 동적으로 생성
export const getNaverLoginLink = () => {
  const naverURL = `${auth_code_path}?client_id=${rest_api_key}&redirect_uri=${redirect_uri}&response_type=code&state=${state_code}`;

  return naverURL;
};

// [네이버] 엑세스 토큰 요청하는 함수 (파라미터는 네이버에서 받은 인가코드다)
// 이 단계까지 진행되어야 마지막 단계인 액세스 토큰을 가지고 사용자 정보를 얻어오는 단계로 넘어갈 수 있다.
export const getAccessToken = async (authCode) => {
  // 헤더 설정
  const header = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  };

  // https://nid.naver.com/oauth2.0/token?grant_type=authorization_code&client_id=jyvqXeaVOVmV&client_secret=527300A0_COq1_XV33cf&code=EIc5bFrl4RibFls1&state=9kgsGTfH4j7IyAkg
  const params = {
    grant_type: "authorization_code",
    client_id: rest_api_key,
    client_secret: rest_api_secret,
    redirect_uri: redirect_uri,
    code: authCode,
    state: state_code,
  };

  const res = await axios.post(access_token_url, params, header);

  const accessToken = res.data.access_token;

  console.log("네이버 토큰정보(res.data) : ", res.data);

  return accessToken;
};

// 서버를 통해서 네이버의 Access Token 을 가져와 서버에서 다시 네이버의 회원정보를 가져온다.
// 최종 결과는 회원정보다.
export const getServerMemberWithAccessToken = async (authCode) => {
  const params = {
    authCode: authCode,
    state: state_code,
  };

  const res = await axios.get(server_access_token_url, { params });

  console.log("네이버 회원정보(res.data) : ", res.data);

  return res.data;
};

// // Access Token 을 서버로 통해서 가져온다.
// export const getServerAccessToken = async (authCode) => {
//   const params = {
//     authCode: authCode,
//     state: state_code,
//   };

//   const res = await axios.get(server_access_token_url, { params });

//   console.log("네이버 토큰정보(res.data) : ", res.data);

//   return res.data;
// };

// // 서버에서 네이버 사용자 정보 가져온다. (네이버에서 발급 받은 토큰 사용)
// export const getMemberWithAccessToken = async (accessToken) => {
//   const res = await axios.get(
//     `${API_SERVER_HOST}/api/member/auth/naver?accessToken=${accessToken}`
//   );
//   return res.data;
// };
