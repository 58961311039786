/**
 * Axios를 사용하여 JWT 인증을 처리하고 토큰 갱신을 관리하는 기능을 구현했다.
 *  API 호출 확장 기능으로 전/후처리, 헤더설정등 커스텀한 설정을 위해 별도의 axios 인스턴스 (jwtAxios) 를 만들어 사용한다.
 * 토큰 요청 -> 만료 -> 재발급 프로세스
 *   > 1. jwtAxios 으로 AccessToken 을 보낸다.
 *   > 2. 응답을 받았는데 토큰이 만료되면 refreshJWT 함수에 accessToken, refreshToken 을 API 로 보내 이 두개 토큰을 재발급 받는다.
 *   > 3. 재발급 받은 두개 토큰을 쿠키에 저장한다.
 *   > 4. 처음 토큰 인증 실패로 응답받은 res 객체에 재발급 받은 accssToken 값으로 변경하고 aixos 를 재 요청한다.
 */

// axios 모듈을 가져와 axios로 이름을 정의.
import axios from "axios";

// getCookie와 setCookie 함수를 가져와서 쿠키 관련 유틸리티 함수를 정의.
import { getCookie, setCookie } from "./cookieUtil";
import { API_SERVER_HOST } from "../api/apiConfig";

// Axios 구분 (2가지)
// A. 엑세스 토큰을 가지고 전달해주는 Axios (jwtAxios)
// B. 토큰 없이 로그인할 때 처럼 전달하는 Axios (axios)

// 엑세스 토큰을 써야 되는 경우(인증이 필요한 곳) ==> jwtAxios.post, jwtAxios.get
// 엑세스 토큰이 필요 없는 경우(로그인) ==> axios.post, axios.get

// ##### 새로운 Axios 인스턴스를 생성한다. #####
// ##### 기본 URL, 헤더, 타임아웃 등 Custom 한 설정을 위해 별도의 axios 인스턴스를 만들어 사용함 #####
const jwtAxios = axios.create();

// !JWT 토큰 갱신 함수 (사일런트 리프레시 방식)
// !refreshJWT 함수는 accessToken과 refreshToken을 매개변수로 받아, 서버에 새로운 액세스 토큰을 요청하는 비동기 함수.
// API 서버의 Refresh 토큰을 가져오는 함수다
// API 서버에서는 토큰이 만료되었다면 새로운 토큰을 생성해 전송해준다.
// 엑세스토큰은 헤더로, 리프래쉬토큰 파라미터로 보낸다)
const refreshJWT = async (accessToken, refreshToken) => {
  const host = API_SERVER_HOST;

  const header = { headers: { Authorization: `Bearer ${accessToken}` } };

  // 기본적인 axios 를 사용해 accssToken 은 헤더에, refreshToken 은 파라미터에 넣어서 API 호출.
  const res = await axios.get(
    `${host}/api/member/refresh?refreshToken=${refreshToken}`,
    header
  );

  return res.data;
};

// (요청이 전송되기 전에 실행, 요청 헤더를 추가, 로그에 기록, 요청 구성 수정하는 등의 작업)
const beforeReq = (config) => {
  console.log("jwtAxios-before request .............. " + config.headers);

  const memberInfo = getCookie("member");

  if (!memberInfo) {
    console.log("Member NOT FOUND");
    return Promise.reject({
      response: {
        data: {
          errorCode: "F001",
          errorMessage: "REQUIRE_LOGIN",
        },
      },
    });
  }

  // initState 은 로그인후 아래 데이터로 대체된다.
  // 초기값은 memberId 만 있다.
  // 로그인이 완료되면 member 정보로 대체된다.(memberIdx, memberId, email, passwd, nickname, memberType, social, refreshToken, tokenExpirationTime, roleNames, accessToken, refreshToken)
  // const initState = {
  //  memberIdx: 1,
  //  memberId: user9@aaa.com,
  // 	email: "user9@aaa.com",
  // 	passwd: "1111",
  // 	nickname: "USER9",
  //  memberType: memberType,
  // 	social: false,
  // 	refreshToken: "aaaa",
  // 	tokenExpirationTime: "aaaa",
  // 	roleNames: ['USER', 'MANAGER', 'ADMIN'],
  //  dto 에 추가 == > claims.put("accessToken", accessToken); claims.put("refreshToken", refreshToken);
  // 	accessToken: "xxxx",
  // 	refreshToken: "xxxx",
  // }

  // 로그인 후 loginSlice 의 초기값은 리듀서의 payload 값으로 대체됨
  // 쿠키에 대체된 데이터가 있다. 쿠키값중 accessToken 을 가져온다.
  const { accessToken } = memberInfo;

  // Authorization 헤더 처리
  // config는 HTTP 요청의 설정을 포함하는 객체로, 주로 다음과 같은 속성을 포함함.
  //   headers: 요청 헤더를 설정하는 객체입니다. 예를 들어, Authorization 헤더를 추가하거나, Content-Type 헤더를 설정하는 데 사용됩니다.
  //   method: HTTP 메서드(GET, POST, PUT 등)를 지정합니다.
  //   url: 요청을 보낼 URL을 지정합니다.
  //   params: 쿼리 파라미터를 설정합니다.
  //   data: POST 또는 PUT 요청의 본문 데이터를 설정합니다.
  //   timeout: 요청의 타임아웃 설정을 지정합니다.
  config.headers.Authorization = `Bearer ${accessToken}`;

  console.log(
    "^^ req-config.headers.Authorization : ",
    config.headers.Authorization
  );

  return config;
};

// 요청 설정 중에 발생하는 오류를 처리하는 함수
// 오류를 로그에 기록하고, 오류가 호출 코드로 전파되도록 거부된 프로미스를 반환
const requestFail = (err) => {
  console.log("jwtAxios-requestFail ...............");

  return Promise.reject(err);
};

// 응답이 호출 코드에 의해 처리되기 전에 호출되는 함수
// 응답 데이터를 변환하거나 정보를 로그에 기록하는 등의 작업
// ! res 매개변수는 Axios의 응답 객체입니다. 이 객체에는 다양한 정보가 포함되어 있습니다.
//     data: 서버에서 받은 응답 데이터가 들어있는 속성입니다. 이 속성을 통해 서버로부터 받은 데이터를 처리할 수 있습니다.
//     status: HTTP 상태 코드가 들어있는 속성입니다. 예를 들어, 성공적인 응답의 경우 200, 실패한 경우에는 4xx나 5xx의 코드가 포함됩니다.
//     statusText: HTTP 상태 코드에 대응하는 텍스트 메시지가 들어있는 속성입니다.
//     headers: 서버에서 받은 응답 헤더가 들어있는 객체입니다.
//     config: Axios 요청에 대한 설정이 들어있는 객체입니다. 이 객체에는 요청에 사용된 URL, 메서드, 헤더 등의 정보가 포함됩니다.
//     request: 요청 객체입니다. 이 객체에는 요청에 대한 자세한 정보가 들어있습니다.
const beforeRes = async (res) => {
  console.log(
    "jwtAxios-beforeRes..........." + res.config.headers + "##" + res.data.error
  );

  const data = res.data;

  // 엑세스 토큰 에러 발생시(만료/Expired) accssToken, refreshToken 재 발급
  // ex) jwtAxios.get(`${prefix}/${tno}`) 호출시 토큰 오류(만료)
  if (data && data.error === "ERROR_ACCESS_TOKEN") {
    const memberCookieValue = getCookie("member");

    // refresh Token 까지 보내서 accessToken 과 refreshToken 토큰을 재 발급 받았다.
    const result = await refreshJWT(
      memberCookieValue.accessToken,
      memberCookieValue.refreshToken
    );
    console.log("^^ refreshJWT RESULT:", result);

    // 새로운 accessToken, refreshToken
    memberCookieValue.accessToken = result.accessToken;
    memberCookieValue.refreshToken = result.refreshToken;

    // 쿠키에 저장할 때 member 에는 JSON 문자열로 저장한다. (쿠키에는 객체를 저장하지 못한다.)
    setCookie("member", JSON.stringify(memberCookieValue), 1);

    // 원래의 호출
    // 위에서 만료된 Access Token 을 사용했기 때문에 자동으로 Refresh Token 을 사용하는 상황까지 완료되었다.
    // 남은 작업은 갱신된 토큰들을 다시 저장하고 원래 원했던 호출을 다시 시도하는 작업이다.
    // ! res는 응답 객체이고, config는 그 요청과 관련된 모든 설정을 포함한다.
    //    > config :    url, method, headers, params, data, timeout, withCredentials, responseType, baseURL, transformRequest, transformResponse
    //                > originalRequest.url, originalRequest.method, originalRequest.headers
    const originalRequest = res.config;

    console.log("^^ res-originalRequest.headers : " + originalRequest.headers);

    // 처음 요청후 응답 받은 객체(토큰 만료 오류), res 객체에서 access Token 만 변경해 그 res 객체를 다시 보낸다.
    // 처음 보냈던 정보 그대로 다시 보내는데 Access Token 만 재발급 받은 값으로 다시 보낸다.
    originalRequest.headers.Authorization = `Bearer ${result.accessToken}`;

    // 이미 originalRequest 에는 헤더 정보가 있기 때문에 jwtAxios 대신 그냥 axios 를 사용한다.
    return await axios(originalRequest); //  전체 요청 구성 객체를 전달 (res.config 는 http 의 전체 정보다)
  }
  return res;
};

// 응답 처리 중에 발생하는 오류를 처리하는 함수
// requestFail과 유사, 오류를 로그에 기록하고, 오류가 호출 코드로 전파되도록 거부된 프로미스를 반환
// * responseFail 함수는 오류를 잡아서 로그를 출력하고, Promise.reject로 오류를 다시 던진다.
// * 이를 통해 모든 API 호출에서 오류를 일관되게 처리할 수 있다.
// * 여기서 오류 표시해도 된다. (alert 또는 modal)
const responseFail = (err) => {
  console.log("jwtAxios-responseFail err a: ", err.response.data);
  if (!err.response) {
    // 네트워크 에러 처리
    alert("서버에 연결할 수 없습니다. 잠시 후 다시 시도해주세요.");
  }
  // message 에는 API 서버에서 전달한 에러메세지로 변경한다. (gson.toJson(Map.of("error", "ERROR_ACCESSDENIED"));)
  //console.log("response fail error[err.response.data.error][status] : " + err.response.data.error +"," + err.response.status)
  //return Promise.reject({...err, message:err.response.data.error});
  return Promise.reject(err.response.data); // err.response.data 객체반환 ==> {erroCode:A001, errorMessage: 'ERROR_ACCESSDENIED'}
};

// 인터셉터는 요청과 응답을 처리하기 위한 강력한 도구로, 로깅, 오류 처리, 요청/응답 수정 등을 수행
// 요청과 응답이 발생하기 전후에 특정 작업을 수행하고, 오류를 처리하는 것

// 요청이 서버로 전송되기 전에 호출 (요청을 서버로 전달되기 전에 가로챈다)
// 매개변수(요청이 서버로 전송되기 전에 호출되는 콜백 함수, 요청이 실패했을 때 호출되는 콜백 함수)
// jwtAxios 를 호출하는 경우에만 아래 코드가 동작 ==> const res = await jwtAxios.get(`${prefix}/${tno}`)
// requestFail 함수가 호출되어 실패를 처리하고, Promise.reject를 사용하여 실패를 전달.
jwtAxios.interceptors.request.use(beforeReq, requestFail);

// 응답이 호출 코드에 전달되기 전에 호출 (응답을 가로챈다)
// HTTP 응답을 받은 후 특정 작업을 수행
// 매개변수 (응답이 호출 코드에 전달되기 전에 호출되는 콜백 함수, 응답 처리 중 오류가 발생할 때 호출되는 콜백 함수)
jwtAxios.interceptors.response.use(beforeRes, responseFail);

export default jwtAxios;
