import React, { Suspense } from "react";
import { Navigate } from "react-router-dom";

import LoadingComponent from "../components/common/LoadingComponent";

import IndextPage from "../pages/zz/IndexPage";
import TypeItPage from "../pages/zz/TypeItPage";
import TypeItJsPage from "../pages/zz/TypeItJsPage";

const Loading = LoadingComponent;

const zzRouter = () => {
  return [
    {
      path: "",
      element: <Navigate replace to="index" />,
    },
    {
      path: "index",
      element: (
        <Suspense fallback={<Loading />}>
          <IndextPage />
        </Suspense>
      ),
    },
    {
      path: "typeit",
      element: (
        <Suspense fallback={Loading}>
          <TypeItPage />
        </Suspense>
      ),
    },
    {
      path: "typeit-js",
      element: (
        <Suspense fallback={Loading}>
          <TypeItJsPage />
        </Suspense>
      ),
    },
  ];
};

export default zzRouter;
