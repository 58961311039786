import React from "react";
import {
  getOrderState,
  getPaymentState,
  getPayType,
} from "../../util/codeUtil";
import { numberWithCommas } from "../../util/numberUtil";

const OrderHistoryRowComponent = ({ data }) => {
  console.log("^^data : ", data);
  return (
    <>
      <ul className="tr">
        <li className="td no" data-txt="no">
          <p>{data.orderNo}</p>
        </li>
        <li className="td date" data-txt="구매일">
          <p>{data.createTime.slice(0, 10)}</p>
        </li>
        <li className="td product" data-txt="상품명">
          <p>{data.orderName}</p>
        </li>
        <li className="td state" data-txt="상태">
          <p>{getOrderState(data.orderState)}</p>
        </li>
        <li className="td payment" data-txt="결제내역">
          <p>
            결제금액 : {numberWithCommas(numberWithCommas(data.totalAmount))}
            <br />
            결제상태 : {getPaymentState(data.paymentState)}
            <br />
            {getPayType(data.payType)} (00000000000)
          </p>
        </li>
      </ul>
    </>
  );
};

export default OrderHistoryRowComponent;
