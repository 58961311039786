import { Suspense } from "react";
import Loading from "../components/common/LoadingComponent";
import Index from "../pages/game/IndexPage";
import Start from "../pages/game/StartPage";

const myRouter = () => {
  return [
    {
      path: "",
      element: (
        <Suspense fallback={<Loading />}>
          <Index />
        </Suspense>
      ),
    },
    {
      path: "index/:pridx",
      element: (
        <Suspense fallback={<Loading />}>
          <Index />
        </Suspense>
      ),
    },
    {
      path: "start/:pridx",
      element: (
        <Suspense fallback={<Loading />}>
          <Start />
        </Suspense>
      ),
    },
  ];
};

export default myRouter;
