// import logo from "./logo.svg";
// import "./css/reset.css?v=0.0.002";
// import "./css/common.css?v=0.0.002";
// import "./css/responsive.css?v=0.0.002";
// import "./css/etc.css?v=0.0.002";

import { RouterProvider } from "react-router-dom";
import root from "./router/root";
import useCustomNetworkStatus from "./hooks/useCustomNetworkStatus";

function App() {
  const api_server_host = process.env.REACT_APP_API_SERVER_HOST;
  const app_api_url = process.env.REACT_APP_CLIENT_HOST;
  const kakao_redirect_url = process.env.REACT_APP_KAKAO_REDIRECT_URL;

  console.log("^^api_server_host : ", api_server_host);
  console.log("^^app_api_url : ", app_api_url);
  console.log("^^kakao_redirect_url : ", kakao_redirect_url);

  const isOnline = useCustomNetworkStatus();

  return (
    <>
      {/* 네트워크 상태에 따라 알림 표시 */}
      {!isOnline && (
        <div
          style={{
            color: "orange",
            textAlign: "center",
            padding: "10px",
            backgroundColor: "#ffcc00",
          }}
        >
          네트워크 연결이 끊어졌습니다. 다시 연결을 시도 중입니다...
        </div>
      )}

      <RouterProvider router={root} />
    </>
  );
}

export default App;
